import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import Introduction from "../../components/Introduction"
import GettingStartedProcess from "../../sections/technology/GettingStartedProcess"
import NextService from "../../sections/services/NextService"

import gettingStartedWithRivvitBgImg from "../../../media/getting-started-with-rivvit-banner-overlay.jpg"

const title = "Getting started with Rivvit is fast and easy"
const description = ""
const introductionTitle ="Grandview leverages Rivvit to provide a fully outsourced Managed Data Service"
const introductionPara = "Our Managed Data Service includes setup and oversight of incoming data feeds, configuration of business rules, and development of dashboards and reports."
const para = [
  introductionPara
]

const GettingStartedWithRivvit = ({ location}: PageProps) => (
  <Layout location={location}>
    <SEO title={title} />
    <SubHero
      backgroundImage={gettingStartedWithRivvitBgImg}
      title={title}
      description={description}
    />
    <Introduction
      title={introductionTitle}
      introduction={para}
    />
    <GettingStartedProcess />
    <NextService
      text="Request a demo of Rivvit"
      link="contact-us"
    />
  </Layout>
)

export default GettingStartedWithRivvit
