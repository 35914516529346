import * as React from "react"
import { Typography, Grid } from "@material-ui/core"

import sourceIcon from "../../../../media/technology/source-icon.png"
import ingestIcon from "../../../../media/technology/ingest-icon.png"
import idwIcon from "../../../../media/technology/data-icon.png"
import workflowIcon from "../../../../media/technology/workflow-icon.png"
import reportingIcon from "../../../../media/technology/reporting-icon.png"

import { useStyles } from "./styles"

const GettingStartedProcess = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="h3" className={classes.description}>
          Here's how it works
        </Typography>

        <Grid container className={classes.box + " " + classes.greyBox}>
          <Grid item xs={12} sm={2} className={classes.iconWrapper}>
            <img src={sourceIcon} alt="source" />
          </Grid>
          <Grid item xs={12} sm={10} className={classes.content}>
            <Typography variant="h3" className={classes.title}>
              Source
            </Typography>
            <div>Push or pull data in native formats.</div><br/>
            <ul>
              <li>CSV</li>
              <li>MS Excel</li>
              <li>XML</li>
              <li>JSON</li>
              <li>API / Web Service</li>
              <li>Direct Database Connection</li>
            </ul>
          </Grid>
        </Grid>

        <Grid container className={classes.box + " " + classes.slateBox}>
          <Grid item xs={12} sm={2} className={classes.iconWrapper}>
            <img src={ingestIcon} alt="ingest" />
          </Grid>
          <Grid item xs={12} sm={10} className={classes.content}>
            <Typography variant="h3" className={classes.title}>
              Ingest
            </Typography>
            <div>Store and cleanse raw data into usable formats</div><br/>
            <ul>
              <li>Store raw data in native format</li>
              <li>Validate file format and underlying records</li>
              <li>Translate values to standard conventions across sources </li>
              <li>Master records based on matching rules</li>
              <li>Apply data hierarchy for each attribute</li>
            </ul>
          </Grid>
        </Grid>

        <Grid container className={classes.box + " " + classes.greyBox}>
          <Grid item xs={12} sm={2} className={classes.iconWrapper}>
            <img src={idwIcon} alt="IDW" />
          </Grid>
          <Grid item xs={12} sm={10} className={classes.content}>
            <Typography variant="h3" className={classes.title}>
              Investment Data Warehouse (IDW)
            </Typography>
            <div>Store golden copy master records for each data domain</div><br/>
            <ul>
              <li>Client Portfolio Hierarchy</li>
              <li>Security Master / Reference Data</li>
              <li>Positions / Lots (IBOR, ABOR)</li>
              <li>Transactions</li>
              <li>Risk &amp; Performance</li>
            </ul>
          </Grid>
        </Grid>

        <Grid container className={classes.box + " " + classes.slateBox}>
          <Grid item xs={12} sm={2} className={classes.iconWrapper}>
            <img src={workflowIcon} alt="workflow" />
          </Grid>
          <Grid item xs={12} sm={10} className={classes.content}>
            <Typography variant="h3" className={classes.title}>
              Workflow Apps
            </Typography>
            <div>Custom applications that drive efficiencies</div><br/>
            <ul>
              <li>Reconciliation</li>
              <li>Data Quality</li>
              <li>Document Management</li>
              <li>Investment Guidelines Monitoring</li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          className={
            classes.box + " " + classes.greyBox + " " + classes.lastBox
          }
        >
          <Grid item xs={12} sm={2} className={classes.iconWrapper}>
            <img src={reportingIcon} alt="Reporting Mart" />
          </Grid>
          <Grid item xs={12} sm={10} className={classes.content}>
            <Typography variant="h3" className={classes.title}>
              Reporting Mart
            </Typography>
            <div>Real-time access to customized outputs</div><br/>
            <ul>
              <li>Custom calculations</li>
              <li>Compliance reports</li>
              <li>Interactive dashboards</li>
              <li>Presentation-quality reports</li>
              <li>Interface to downstream systems</li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default GettingStartedProcess
