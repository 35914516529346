import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: 60,
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 700,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50
  },
  description: {
    textAlign: "center",
    marginBottom: 20,
    fontSize: "2.2rem",
    fontFamily: "Roboto medium"
  },
  startupProcessInfoGraphImg: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },
  box: {
    width:"100%",
    position: "relative",
    display: "flex",
    color: "#fff",
    padding: 30,
    marginTop: 30,
    "&::after": {
      content: `''`,
      position: 'absolute',
      left: '50%',
      top: '99%',
      width: '0',
      height: '0',
      marginLeft: "-30px",
      border: '30px solid transparent',
    },
    "& ul": {
      fontSize: "1rem",
      lineHeight: "1.6rem",
      listStyleType: "square",
      padding: "0 22px",
      margin: 0
    }
  },
  greyBox: {
    backgroundColor: "#2697db",
    "&::after": {
      borderTopColor: '#2697db',
    }
  },
  slateBox: {
    backgroundColor: "#6B8792",
    "&::after": {
      borderTopColor: '#6B8792',
    }
  },
  lastBox: {
    "&::after": {
      border: '0px solid transparent',
    }
  },
  iconWrapper: {
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    },
  },
  content: {
    fontSize: "1rem",
    padding: "0px 20px"
  },
  title: {
    fontSize: "1.4rem",
    lineHeight: "2rem",
    paddingBottom: 10
  },
}))
